*, :after, :before {
  box-sizing: border-box;
}

:root {
  --color-text: #fbf5f3;
  --color-bg: #0d0208;
  --color-link: #008f11;
  --color-link-hover: #00ff41;
  font-size: 18px;
}

body {
  color: var(--color-text);
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: lowercase;
  margin: 0;
  font-family: ballinger-mono, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
}

canvas {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.js .loading:before, .js .loading:after {
  content: "";
  z-index: 1000;
  position: fixed;
}

.js .loading:before {
  background: var(--color-bg);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.js .loading:after {
  opacity: .4;
  background: var(--color-link);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  animation: .7s linear infinite alternate forwards loaderAnim;
  top: 50%;
  left: 50px;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(.5, .5, 1);
  }
}

a {
  color: var(--color-link);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

a:not(.frame__title-back) {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

a:focus {
  background: #d3d3d3;
  outline: none;
}

a:focus:not(:focus-visible) {
  background: none;
}

a:focus-visible {
  background: none;
  outline: 2px solid red;
}

.unbutton {
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.unbutton:focus {
  outline: none;
}

main {
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.frame {
  color: var(--color-title);
  grid-gap: 2rem;
  text-transform: lowercase;
  align-items: start;
  margin-bottom: 3rem;
  padding: 1rem 2rem;
  font-size: .75rem;
}

.frame a:not(.frame__title-back):before {
  content: "";
  transform-origin: 0%;
  background: currentColor;
  width: 100%;
  height: 1px;
  transition: transform .3s;
  position: absolute;
  top: 90%;
}

.frame a:not(.frame__title-back):hover:before {
  transform-origin: 100%;
  transform: scaleX(0);
}

.frame__title {
  grid-area: title;
  display: flex;
}

.frame__title-main {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.frame__title-back {
  align-items: flex-end;
  display: flex;
  position: relative;
}

.frame__title-back span {
  display: none;
}

.frame__title-back svg {
  fill: currentColor;
}

.frame__prev {
  align-self: start;
  padding-inline: 12px;
}

.ascii {
  color: var(--color-link);
  opacity: .5;
  pointer-events: none;
  position: fixed;
  top: -1rem;
  right: 0;
}

.content {
  gap: 1rem;
  max-width: 830px;
  padding: 7vh 2rem 0;
  display: grid;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0;
}

.effects {
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: auto;
  padding: 2rem;
  display: flex;
}

.effects button {
  color: var(--color-link);
  white-space: nowrap;
  text-transform: inherit;
  font: inherit;
  cursor: pointer;
  text-transform: lowercase;
  background: none;
  border: 0;
  font-size: .75rem;
}

.effects button:before {
  content: "[";
  margin-right: .5rem;
  display: inline-block;
}

.effects button:after {
  content: "]";
  margin-left: .5rem;
  display: inline-block;
}

.effects button:hover {
  color: var(--color-link-hover);
}

.splitting .words .word {
  white-space: nowrap;
}

@media screen and (width >= 53em) {
  .frame {
    grid-template: "title prev sponsor"
    / auto auto 1fr;
    justify-content: start;
    display: grid;
  }

  .content {
    grid-template-columns: 15ch 1fr;
  }
}
/*# sourceMappingURL=index.724ff63a.css.map */
